import React from 'react'
import { LearningStagesCart } from '../../components';
import { images } from '../../constants';

import SwiperCore, { Pagination, Navigation, HashNavigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import './LearningStages.scss';

const LearningStages = () => {
  const stages = [{
    title:'ЗАПИС В АВТОШКОЛУ',
    backgroundImg: images.educationWeb
  },
  {
    title:'ТЕОРЕТИЧНЕ НАВЧАННЯ',
    backgroundImg: images.educationMans
  },
  {
    title:'ТЕОРЕТИЧНИЙ ІСПИТ В СЕРВІСНОМУ ЦЕНТРІ МВС',
    backgroundImg: images.educationMalko
  },
  {
    title:'ПРАКТИЧНІ ЗАНЯТТЯ',
    backgroundImg: images.educationCar
  },
  {
    title:'ПРАКТИЧНИЙ ІСПИТ В АВТОШКОЛІ',
    backgroundImg: images.educationMegan
  },
  {
    title:'ПРАКТИЧНИЙ ІСПИТ В СЕРВІСНОМУ ЦЕНТРІ МВС',
    backgroundImg: images.educationMegan2
  }];

  SwiperCore.use([Pagination, Navigation, HashNavigation]);

  return (
    <div className='app__learningStages' name='learning'>
      <p className='app__learningStages-title'>ЕТАПИ НАВЧАННЯ</p>
      <div className='app__learningStages-list'>
        {stages.map((stage, index) => (
          <LearningStagesCart
            key={index}
            title={stage.title}
            backgroundImg={stage.backgroundImg}        
          />
        ))}
      </div>
      <div className='app__learningStages-mobile-list'>
      <Swiper
        cssMode={true}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        className="mySwiper">
          {stages.map((stage, index) => (
            <SwiperSlide key={index} className='app__learningStages-mobile-list-item'>
              <LearningStagesCart
                key={index}
                title={stage.title}
                backgroundImg={stage.backgroundImg}        
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default LearningStages