import React from 'react'
import { images } from '../../constants';
import { BsInstagram, BsFacebook, BsTelegram } from 'react-icons/bs';
import { MdLocationPin } from 'react-icons/md';

import './Footer.scss';

const Footer = () => {
  return (
    <div className='app__footer'>
      <div className="app__footer-item">
        <img src={images.logoFooter} alt="logo" loading="lazy"/>
      </div>
      <div className="app__footer-item">
        <a className="app__footer-phone-number" href="tel:+380 (93) 180 1177">+380 (93) 180 1177</a>
        <a className="app__footer-phone-number" href="tel:+380 (98) 180 1177" style={{ marginTop: '10px' }}>+380 (98) 180 1177</a>
      </div>
      <div className="app__footer-item">
        <div className="app__footer-address-block">
          <MdLocationPin size={32} color="#FFFFFF" />
          <a href='https://goo.gl/maps/nKdCkwEYPmba2j6H8' target="_blank" rel="noopener noreferrer" className="app__footer-address">Вул. Замарстинівська 162а (трц Спартак)</a>
        </div>
        <div className="app__footer-address-block">
          <MdLocationPin size={32} color="#FFFFFF" />
          <a href='https://goo.gl/maps/SA29J62TX2sfeyoB7' target="_blank" rel="noopener noreferrer" className="app__footer-address">Вул. Академіка Колесси 2 (центр)</a>
        </div>
        <div className="app__footer-address-block">
          <MdLocationPin size={32} color="#FFFFFF" />
          <a href='https://goo.gl/maps/TVQdCMEW9AED5PH38' target="_blank" rel="noopener noreferrer" className="app__footer-address">вул. Наукова 7А (БЦ "ОПТІМА ПЛАЗА")</a>
        </div> 
        <div className="app__footer-address-block">
          <MdLocationPin size={32} color="#FFFFFF" />
          <a href='https://maps.app.goo.gl/RUJ5dQW7UVuGRJmu6?g_st=it' target="_blank" rel="noopener noreferrer" className="app__footer-address">пл. Князя Святослава 5. (жк. "Семицвіт")</a>
        </div>
        <div className="app__footer-address-block">
          <MdLocationPin size={32} color="#FFFFFF" />
          <a href='https://maps.app.goo.gl/5xyfiZrxwexbXw4v5' target="_blank" rel="noopener noreferrer" className="app__footer-address" style={{ marginTop: '10px' }}>Вул.Водогінна 2 (Львівводоканал)</a>
        </div>
      </div>
      <div className="app__footer-item app__footer-sn">
        <div className="app__footer-sn-row">
          <a href="https://instagram.com/autoinstruktor.lviv?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer" >
            <BsInstagram size={24} color="white" className='app__footer-sn-item' />
          </a>
          <a href="https://www.facebook.com/people/autoinstructorlviv/100047391673967/" target="_blank" rel="noreferrer">
            <BsFacebook size={24} color="white" className='app__footer-sn-item' />
          </a>
        </div>
        <div className="app__footer-sn-row">
          <a href="https://telegram.me/TAURUS_LVIV" target="_blank" rel="noreferrer">
            <BsTelegram size={24} color="white" className='app__footer-sn-item' />
          </a>
          <a href="viber://chat?number=0938001062" style={{ width: '42px' }} target="_blank" rel="noreferrer">
            <img src={images.viber} className="app__footer-sn-item app__svg-viber" href="viber://chat?number=0938001062" alt="viber" loading="lazy"/>
          </a>
        </div>
        <a id="publicContract" href={images.publicContract} target="_blank">Публічний договір</a>
      </div>
    </div>
  )
}

export default Footer