import React from 'react';

import './DocumentCard.scss';

const DocumentCard = ({ id, img, text }) => {
  return (
    <div className='app__documentCard'>
        <img className='app__documentCard-img' src={img} loading="lazy"/>
        <div className='app__documentCard-text'>{text}</div>
        <h1 className='app__documentCard-id'>{id}</h1>
    </div>
  );
};

export default DocumentCard;
