import React, { useContext } from 'react';
import AppContext from '../../context/AppContext'

import './Card.scss';

const Card = ({ index, title, subtitle, contentTitle, content, date }) => {
  const { toggleVisibility } = useContext(AppContext);

  return (
    <div key={index} className="card">
      <div className="card__header">
          <h2 className="card__header__title">{title}</h2>
          <p className="card__header__subtitle">{subtitle}</p>
      </div>
      <hr/>
      <div className="card__body">
          <h3 className="card__body__content-title">{contentTitle}</h3>
          <ul>
            {content.map((item, index) => (
              <li key={index} className="card__body__content">{item.text}</li>
            ))}
          </ul>
      </div>
      <div className="card__footer">
          <div className="card__footer__date">
            <i className="fas fa-clock card__footer__date-icon"></i>
            <button onClick={toggleVisibility} className="card__footer__date-button">{date}</button>
          </div>
      </div>
    </div>
  );
};

export default Card;
