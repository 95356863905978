import React from "react";
import { motion } from 'framer-motion';

import "./AdvantageCard.scss";

const AdvantageCard = ({ iterator, iconSrc, title, description, bgImg }) => {
  const cardVariants= {
    offscreen: {
      y: 30,
      scale: 0.1
    },
    onscreen: {
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8
      }
    }
  };

  return (
    <motion.div className="card-container"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: false, amount: 0.2 }}>
      <section className="img-card-section">
        <div className="img-card-square"></div>
        <img alt="background" className="img-card-style" src={bgImg}></img>
      </section>
      <motion.section 
      variants={cardVariants}
      className="name-card-section">
        {title}
      </motion.section>
      <motion.section variants={cardVariants} className="desc-card-section">
        {description}
      </motion.section>
      <motion.img variants={cardVariants} alt="profile" className="profile-img-card" src={iconSrc}></motion.img>
    </motion.div>
  );
};

export default AdvantageCard;