import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import AppContext from '../../context/AppContext'

import { images } from '../../constants';
import './SpecialOffer.scss';

const SpecialOffer = () => {
  const { toggleVisibility } = useContext(AppContext);
  const cardVariants= {
    offscreen: {
      y: 30,
      scale: 0.2
    },
    onscreen: {
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 2
      }
    }
  };

  return (
  <div id='home'>
    <motion.div 
      className="app__specialoffer app__flex" 
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: false, amount: 0.01 }}>
      <motion.div
        variants={cardVariants}
        className="app__specialoffer-info"
      >
        <div className="app__specialoffer-badge">
          <div className="badge-cmp app__flex">
            <h1 className="head-text">ЗНИЖКА ВСІМ -1000 ГРН</h1>
          </div>

          <div className="tag-cmp app__flex">
            <p className="p-text">ПРИ ВИБОРІ ПАКЕТУ ЕКСПЕРТ</p>
            {/*<span style={{color: "white"}}>-1000 Грн</span>*/}
            {/*<p className="p-text-students">знижка для студентів 500 гривень</p>*/}
            {/*<button onClick={toggleVisibility}>Залишити заявку</button>*/}
          </div>
        </div>
      </motion.div>

      <motion.div
        variants={cardVariants}
        className="app__specialoffer-img"
      >
        <img src={images.maincar} alt="profile_bg" loading="lazy"/>
        <motion.img
          variants={cardVariants}
          src={images.bigLetterY}
          alt="profile_circle"
          className="overlay_circle"
        />
      </motion.div>
    </motion.div>
  </div>
)};

export default SpecialOffer;