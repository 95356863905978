import React, { createContext, useState } from "react";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <AppContext.Provider value={{ isVisible, toggleVisibility }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
