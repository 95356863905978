import React, { useState } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion, AnimatePresence } from 'framer-motion';
import { Link, animateScroll as scroll } from 'react-scroll'

import { images } from '../../constants';
import './Navbar.scss';

const Navbar = ({isHidden}) => {
  const [toggle, setToggle] = useState(false);
  const menuItems = [
    {
      text: "про нас",
      section: "header"
    },
    {
      text: "наші переваги",
      section: "advantages"
    },
    {
      text: "навчання",
      section: "learning"
    },
    {
      text: "ціни",
      section: "prices"
    },
    {
      text: "документи",
      section: "documents"
    } 
  ];

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.logo} loading="lazy" alt="logo" />
      </div>
      <ul className="app__navbar-links">
        {menuItems.map((item, index) => (
          <li className="app__flex p-text" key={index}>
            <div></div>
            <Link to={item.section} spy={true} smooth={true} duration={1000}>{item.text}</Link>
          </li>
        ))}
      </ul>

      {isHidden ? null : 
        <div className="app__navbar-menu">
          <HiMenuAlt4 onClick={() => setToggle(true)} />

          <AnimatePresence>
            {toggle && (
              <motion.div
                key="menu"
                initial={{ opacity: 0, x: 300 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 300 }}
                transition={{ duration: 0.5, ease: 'easeOut' }}
              >
                <HiX onClick={() => setToggle(false)} />
                <ul>
                  {menuItems.map((item, index) => (
                    <li key={index}>
                      <Link 
                        to={item.section} 
                        spy={true} smooth={true} 
                        duration={1000}
                        offset={-100}
                        onClick={() => setToggle(false)}>
                          {item.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      }
    </nav>
  );
};

export default Navbar;
