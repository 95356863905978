import React from 'react'
import { images } from '../../constants';
import { DocumentCard } from '../../components';
import { motion } from 'framer-motion';

import './Documents.scss';

const Documents = () => {
  const documents = [{
    'id': 1,
    'img': images.documentPassport,
    'text': 'КСЕРОКОПІЯ ПАСПОРТУ'
  },
  {
    'id': 2,
    'img': images.documentIdentificationCode,
    'text': 'КСЕРОКОПІЯ ІДЕНТИФІКАЦІЙНОГО КОДУ'
  },
  {
    'id': 3,
    'img': images.documentRegistrationPlace,
    'text': 'КСЕРОКОПІЯ ДОВІДКИ ПРО МІСЦЕ РЕЄСТРАЦІЇ (ДЛЯ ТИХ, У КОГО ID КАРТКА)'
  },
  {
    'id': 4,
    'img': images.documentMedical,
    'text': 'МЕДИЧНА ДОВІДКА ФОРМИ 083'
  },
  {
    'id': 5,
    'img': images.documentPhoto,
    'text': 'ФОТОКАРТКА РОЗМІРОМ 3*4 СМ'
  }];

    const cardVariants= {
    offscreen: {
      y: 30,
      scale: 0.2
    },
    onscreen: {
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 2
      }
    }
  };

  return (
    <div className='app__documents' name='documents'>
      <div className='app__documents-info'>
        <p className='app__documents-title'>Документи</p>
          <div className='app__documents-list'>
            {documents.map((document, index) => (
              <motion.div
                key={index}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.5 }}      
                className='app__documents-item'>
                  <motion.div variants={cardVariants}>
                  <DocumentCard
                    id={document.id}
                    img={document.img}
                    text={document.text}
                  />
                  </motion.div>
              </motion.div>
            ))}
          </div>
      </div>
    </div>
  )
}

export default Documents