import React from 'react';

import { Navbar, ContactForm } from './components';
import { AppProvider } from './context/AppContext';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import { 
    Advantages,
    Documents, 
    Footer, 
    Header, 
    LearningStages, 
    Locations, 
    PhotoGallery, 
    Prices,
    PricePack, 
    SpecialOffer,
    ThankYou,
    UpdateForm } from './container';

import './App.scss'

const App = () => ( 
  <div className="app">
    <AppProvider>
      <Routes>
        <Route path="/" element= {
        <>
          <Navbar isHidden= {false}/>
          <Header />
          <Locations />
          <PhotoGallery />
          <Advantages />
          <LearningStages />
          {/*<Prices />*/}
          <PricePack />
          <Documents />
          <SpecialOffer />
          <ContactForm />
        </>} />
      </Routes>
      <Routes>
        <Route path="/thanks" element= {
          <>
            <Navbar isHidden= {true}/>
            <ThankYou/>
          </>} />
      </Routes>
      <Routes>
        <Route path="/update-form" element={<UpdateForm />} />
      </Routes>
      <Footer />
    </AppProvider>
  </div>
);

export default App;