import React from 'react';
import { motion } from 'framer-motion';

import './LearningStagesCart.scss';

const LearningStagesCart = ({ title, backgroundImg }) => {
  const cardVariants= {
    offscreen: {
      y: 30,
      scale: 0.1
    },
    onscreen: {
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 1.1
      }
    }
  };

  return (
    <motion.div 
    initial="offscreen"
    whileInView="onscreen"
    viewport={{ once: false, amount: 0.2 }}
    className="LearningStagesCart" 
    style={{ backgroundImage: `url(${backgroundImg})` }}>
      <motion.div variants={cardVariants} className="title">{title}</motion.div>
      <div className="content">
      </div>
    </motion.div>
  );
};

export default LearningStagesCart;
