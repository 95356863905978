import React from 'react'
import { motion } from 'framer-motion';
import { images } from '../../constants';
import { AdvantageCard } from '../../components';

import SwiperCore, { Pagination, Navigation, HashNavigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import './Advantages.scss';

const Advantages = () => {
  const advantages = [
    {
      img: images.maincar,
      desktopImg: images.sportCar,
      bgImg: images.photoGallery7,
      title:'Автопарк',
      desktopTitle:'Сучасний автопарк',
      description:'Cучасний автопарк складається з найнових та передових моделей автомобілів. Ми прагнемо забезпечити нашим студентам найвищий рівень безпеки та комфорту під час навчання водінню.'
    },
    {
      img: images.advantageMap,
      desktopImg: images.map,
      bgImg: images.educationMans,
      title:'Розташування',
      desktopTitle:'Автошкола у Львові має вигідне розташування філій',
      description:'Автошкола у Львові має вигідне розташування філій'
    },
    {
      img: images.advantageLaptop,
      desktopImg: images.lecture,
      bgImg: images.educationWeb,
      title:'Сучасність',
      desktopTitle:'Технологічно обладнані навчальні класи для легкого засвоєння теоретичного матеріалу. Інтерактивний підхід до вивчення нудної теорії, розбір реальних ситуацій під час навчання в автошколі',
      description:'Технологічно обладнані навчальні класи для легкого засвоєння теоретичного матеріалу. Інтерактивний підхід до вивчення нудної теорії, розбір реальних ситуацій під час навчання в автошколі'
    },
    {
      img: images.advantageMan,
      desktopImg: images.instructor,
      bgImg: images.educationCar,
      title:'Професійність',
      desktopTitle:'Атестовані та надзвичайно професійні інструктори з багаторічним досвідом навчання водіїв, із задоволенням розкриють секрети безпечного і комфортного водіння',
      description:'Атестовані та надзвичайно професійні інструктори з багаторічним досвідом навчання водіїв, із задоволенням розкриють секрети безпечного і комфортного водіння'
    },
    {
      img: images.advantageMoney,
      desktopImg: images.moneyBack,
      bgImg: images.photoGallery1,
      title:'Відшкодування',
      desktopTitle:'100 відсоткове повернення коштів, якщо вам не сподобається наше навчання (до моменту реєстрації в системі МВС)',
      description:'100 відсоткове повернення коштів, якщо вам не сподобається наше навчання (до моменту реєстрації в системі МВС)'
    }
  ];

  SwiperCore.use([Pagination, Navigation, HashNavigation]);

  return (
    <div className='app__advantages' name='advantages'>
    <img className='app__advantages-signs-education' src={images.signsEducation} loading="lazy"/>
      <div className='app__advantages-info'>
        <p className='app__advantages-title'>НАШІ ПЕРЕВАГИ</p>
          <div className='app__advantages-info-desktop'>
            {advantages.map((advantage, index) => (
              <motion.div
                key={index}
                whileInView={{ x: [-100, 0], opacity: [0, 1] }} 
                transition={{ duration: 0.7 }}
                className='app__advantages-item'>
                  <img src={advantage.desktopImg} loading="lazy"/>
                  <div>{advantage.desktopTitle}</div>
              </motion.div>
            ))}
          </div>
          <div className='app__advantages-info-mobile'>
          <Swiper
            cssMode={true}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            className="mySwiper">
              {advantages.map((advantage, index) => (
                <SwiperSlide>
                  <div key={index} className='card-panel'>
                    <AdvantageCard
                      title={advantage.title}
                      description={advantage.description}
                      iconSrc={advantage.img}
                      iterator={1}
                      bgImg={advantage.bgImg}
                    />
                  </div>
                  </SwiperSlide>
              ))}
            </Swiper>
          </div>
      </div>
      <div className='app__advantages-cars'>
        <motion.img 
          whileInView={{ x: [100, 0], opacity: [0, 1] }} 
          transition={{ duration: 0.9, ease: 'easeInOut' }}
          className='app__advantages-cars-ford' 
          src={images.ford}/>
        <motion.img
          whileInView={{ x: [100, 0], opacity: [0, 1] }} 
          transition={{ duration: 1.1, ease: 'easeInOut' }}
          className='app__advantages-cars-megan' 
          src={images.megan}/>
      </div>     
    </div>
  )
}

export default Advantages