import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

import { images } from '../../constants';
import './ThankYou.scss';

const ThankYou = () => {
  const cardVariants= {
    offscreen: {
      y: 30,
      scale: 0.2
    },
    onscreen: {
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 2
      }
    }
  };

  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(window.scrollTo(0, 0), 100);
  }, [pathname]);
  
  const navigate = useNavigate();
  function handleClick() {
    navigate("/");
  }

  return (
  <div id='home' name='header'>
    <motion.div
      className="app__header app__flex" 
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: false, amount: 0.5 }}>
      <motion.div
        variants={cardVariants}
        className="app__header-info"
      >
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
            <h1 className="head-text">Ура! Ми отримали вашу заявку</h1>
          </div>

          <div className="tag-cmp app__flex">
            <p className="p-text">Наші менеджери зателефонують вам найближчим часом</p>
            <button onClick={handleClick}>Повернутися на головну</button>
          </div>
        </div>
      </motion.div>

      <div className="app__header-img">
        <img src={images.maincar} alt="profile_bg" loading="lazy"/>
        <motion.img
          variants={cardVariants}
          src={images.bigLetterY}
          alt="profile_circle"
          className="overlay_circle"
        />
      </div>
    </motion.div>
  </div>
  
  );
}

export default ThankYou;