import React, { useState, useEffect }from 'react';
import { motion } from 'framer-motion';

import { images } from '../../constants';

import "./UpdateForm.scss";

function UpdateForm() {
  const [formData, setFormData] = useState({
    pipe_id: '',
    name: '',
    birthday: '',
    adress: '',
    ipn: '',
    passport: '',
    pio: '',
    pio_data: '',
    filial: '',
    transmission: '',
    edu_program: '',
    quantity: '',
    signing_date: '',
  });
  
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const piplineid = searchParams.get('piplineid');

    if (piplineid) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        pipe_id: piplineid,
      }));
    }
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const cardVariants= {
    offscreen: {
      y: 30,
      scale: 0.2
    },
    onscreen: {
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 2
      }
    }
  };

  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);
  
    try {
      const response = await fetch('https://taurus.school/update-student.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        throw new Error('Помилка відправки форми');
      }
  
      setFormData({
        pipe_id: '',
        name: '',
        birthday: '',
        adress: '',
        ipn: '',
        passport: '',
        pio: '',
        pio_data: '',
        filial: '',
        transmission: '',
        edu_program: '',
        quantity: '',
        signing_date: '',
      });
  
      setMessage('Картка успішно оновлена');
      
      // Повідомлення зникне через 3 секунди
      setTimeout(() => {
        setMessage('');
      }, 3000);
    } catch (error) {
      console.error(error.message);
      setMessage('Помилка відправки форми');
    }
  };  

  return (
    <>
    <div id='home' name='header'>
      <motion.div
        className="app__header app__flex" 
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: false, amount: 0.5 }}>
        <motion.div
          variants={cardVariants}
          className="app__header-info"
        >
          <div className="app__header-badge">
            <div className="badge-cmp app__flex">
              <h2 className="head-text">Заповніть форму нижче</h2>
            </div>
          </div>
        </motion.div>

        <div className="app__header-img">
          <img src={images.maincar} alt="profile_bg" loading="lazy"/>
          <motion.img
            variants={cardVariants}
            src={images.bigLetterY}
            alt="profile_circle"
            className="overlay_circle"
          />
        </div>
      </motion.div>
    </div>
    <div className='update-form'>
      <div className="update-form__body">
        <h2 className="update-form__header trs__header _center-header">Заповніть форму</h2>
        {<p className="update-form__desc">Будь ласка, переконайтеся, що ви перейшли на цю сторінку за спеціальним посиланням, надісланим Вам менеджером.</p>}
        <div className="update-form__form">
          <div className="student-form__bg">
            <form id="student-form" className="student-form lead-form" onSubmit={handleSubmit}>
              <input required type="hidden" name="pipe_id" value={formData.pipe_id}/>
              <div className="input-group">
                  <input 
                      required 
                      autoComplete="off" 
                      className={`update-form__input ${formData.name.length > 0 ? 'filled' : ''}`} 
                      type="text" 
                      id="name" 
                      name="name" 
                      placeholder="Степенко Тарас Павлович" 
                      value={formData.name}
                      onChange={handleChange}
                  />
                  <label htmlFor="name" className="input-label">ПІБ</label>
              </div>

              <div className="input-group">
                  <input 
                      required 
                      autoComplete="off" 
                      className={`update-form__input birthday-input ${formData.birthday.length > 0 ? 'filled' : ''}`}
                      type="text" 
                      id="birthday" 
                      name="birthday" 
                      placeholder="Дата народження" 
                      value={formData.birthday}
                      onChange={handleChange}
                  />
                  <label htmlFor="birthday" className="input-label">Дата народження</label>
              </div>

              <div className="input-group">
                  <input 
                      required 
                      autoComplete="off" 
                      className={`update-form__input adress-input ${formData.adress.length > 0 ? 'filled' : ''}`} 
                      type="text" 
                      id="adress" 
                      name="adress" 
                      placeholder="м. Київ, вул. Чорновола, 25" 
                      value={formData.adress}
                      onChange={handleChange}
                  />
                  <label htmlFor="adress" className="input-label">Адреса реєстрації</label>
              </div>

              <div className="input-group">
                  <input 
                      required 
                      autoComplete="off" 
                      className={`update-form__input ipn-input ${formData.ipn.length > 0 ? 'filled' : ''}`} 
                      type="text" 
                      id="ipn" 
                      name="ipn" 
                      placeholder="1234567778" 
                      value={formData.ipn}
                      onChange={handleChange}
                  />
                  <label htmlFor="ipn" className="input-label">Індетифікаційний номер (ІПН)</label>
              </div>

              <div className="input-group">
                  <input 
                      required 
                      autoComplete="off" 
                      className={`update-form__input passport-input ${formData.passport.length > 0 ? 'filled' : ''}`} 
                      type="text" 
                      id="passport" 
                      name="passport" 
                      placeholder="СК 123456" 
                      value={formData.passport}
                      onChange={handleChange}
                  />
                  <label htmlFor="passport" className="input-label">Серія та номер паспорту</label>
              </div>

              <div className="input-group">
                  <input 
                      required 
                      autoComplete="off" 
                      className={`update-form__input pio-input ${formData.pio.length > 0 ? 'filled' : ''}`} 
                      type="text" 
                      id="pio" 
                      name="pio" 
                      placeholder="Київським РВ УМВС України в Дніпровському районі" 
                      value={formData.pio}
                      onChange={handleChange}
                  />
                  <label htmlFor="pio" className="input-label">Ким видано</label>
              </div>

              <div className="input-group">
                  <input 
                      required 
                      autoComplete="off" 
                      className={`update-form__input pio_data-input ${formData.pio_data.length > 0 ? 'filled' : ''}`} 
                      type="text" 
                      id="pio_data" 
                      name="pio_data" 
                      placeholder="Дата видачі паспорту" 
                      value={formData.pio_data}
                      onChange={handleChange}
                  />
                  <label htmlFor="pio_data" className="input-label">Дата видачі паспорту</label>
              </div>

              <div className="input-group">
                  <input 
                      required 
                      autoComplete="off" 
                      className={`update-form__input quantity-input ${formData.quantity.length > 0 ? 'filled' : ''}`} 
                      type="number" 
                      id="quantity" 
                      name="quantity" 
                      placeholder="10" 
                      value={formData.quantity}
                      onChange={handleChange}
                  />
                  <label htmlFor="quantity" className="input-label">Кількість занять</label>
              </div>

              <div className="input-group">
                  <select 
                      name="filial" 
                      className="update-form__input" 
                      value={formData.filial}
                      onChange={handleChange}
                      required
                  >
                      <option value="" disabled>Філія автошколи</option>
                      <option value='Онлайн платформа'>Онлайн платформа</option>
                      <option value='Онлайн зум'>Онлайн зум</option>
                      <option value='Академіка Колеси 2 (центр)'>Академіка Колеси 2 (центр)</option>
                      <option value='Вул. Замарстинівська 162а (біля тц Спартак)'>Вул. Замарстинівська 162а (біля тц Спартак)</option>
                      <option value='ЛЬВІВ Вул.Наукова 7а (б/ц" Оптіма Плаза")'>ЛЬВІВ Вул.Наукова 7а (б/ц" Оптіма Плаза")</option>
                      <option value='Пл.Князя Святослава 5 ( біля жк."Семицвіти")'>Пл.Князя Святослава 5 ( біля жк."Семицвіти")</option>
                  </select>
              </div>

              <div className="input-group">
                  <select 
                      name="transmission" 
                      className="update-form__input" 
                      value={formData.transmission}
                      onChange={handleChange}
                      required
                  >
                      <option value="" disabled>Коробка передач</option>
                      <option value="Механіка">Механіка</option>
                      <option value="Автомат">Автомат</option>
                  </select>
              </div>

              <div className="input-group">
                  <select 
                      name="edu_program" 
                      className="update-form__input" 
                      value={formData.edu_program}
                      onChange={handleChange}
                      required
                  >
                      <option value="" disabled>Курс навчання</option>
                      <option value="Теоретичний курс ">Теоретичний курс </option>
                      <option value="Практичний курс">Практичний курс</option>
                      <option value="Експерт пакет">Експерт пакет</option>
                  </select>
              </div>

              <div className="input-group">
                  <input 
                      required 
                      autoComplete="off" 
                      className={`update-form__input signing_date-input ${formData.signing_date.length > 0 ? 'filled' : ''}`} 
                      type="text" 
                      id="signing_date" 
                      name="signing_date" 
                      placeholder="Дата підписання договору" 
                      value={formData.signing_date}
                      onChange={handleChange}
                  />
                  <label htmlFor="signing_date" className="input-label">Дата підписання договору</label>
              </div>

              <button type="submit" id="submit_button" className="btn btn-brand">Надіслати</button>
              {message && <div style={{color: 'white', textAlign: 'center', padding: '10px'}}>{message}</div>}
            </form>
          </div>
        </div>
      <p className="update-form__policy"><i>Як зазначено в нашій угоді про конфіденційність, ми підтверджуємо, що всі види інформації не будуть передані третім особам жодним чином, крім відповідної установи в рамках правових норм.</i></p>
      </div>
    </div>
  </>
  );
}

export default UpdateForm;
