import React, {useState, useEffect} from 'react'
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import { Pagination, Perspective } from "@egjs/flicking-plugins";
import { MdLocationPin } from 'react-icons/md';
import { Card } from '../../components';
import { motion } from 'framer-motion';
import { client } from '../../client';

import "@egjs/flicking-plugins/dist/pagination.css";
import "@egjs/react-flicking/dist/flicking.css";

import './Locations.scss';

const Locations = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const query = '*[_type == "location"]';

    client.fetch(query).then((data) => {
      setLocations(data);
    });
  }, []);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 1100);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderMobileLocation = () => {
    return (
      <>
        <p className='app__locations-mobile-title'>
            ВСТИГНИ ЗАПИСАТИСЬ В НОВИЙ НАБІР
        </p>
        {locations.map((location, index) => (
          <div key={index} className='app__locations-mobile-set'>
            <div className="app__locations-mobile-address">
              <div>
                <MdLocationPin size={32} color="#FFFFFF" />
              </div>
              <div className='address-text'>
                {location.address}
              </div>
            </div>
            <div className={'app__locations-mobile-lesson-types'}>
                <Flicking className={'app__locations-mobile-flicking'} circular={true} plugins={[new Perspective({ rotate: 0.5 }), new Pagination({ type: 'bullet' })]}>
                  {location.lessonTypes.map((lessonType, index) => (
                    <div key={index} className="card-panel">
                      <Card
                        index={index}
                        title={lessonType.title}
                        subtitle={lessonType.subtitle}
                        contentTitle={lessonType.contentTitle}
                        content={lessonType.content}
                        finalTitle={lessonType.finalTitle}
                        date={lessonType.date}
                      />
                    </div>
                  ))}
                  <ViewportSlot>
                    <div className="flicking-pagination app__locations-mobile-flicking-pagination"></div>
                  </ViewportSlot>
                </Flicking>
            </div>
          </div>
        ))}
      </>
    );
  }

  const renderDesktopLocation = () => {
    return (
      <>
        <motion.p 
          whileInView={{ x: [-100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.5 }} 
          className='app__locations-desktop-title'>ВСТИГНИ ЗАПИСАТИСЬ В НОВИЙ НАБІР
        </motion.p>
        {locations.map((location, index) => (
          <div key={index} className='app__locations-desktop-set'>
            <motion.div
              whileInView={{ x: [-100, 0], opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className='app__locations-desktop-address'>
                <div>
                  <MdLocationPin size={32} color="#FFFFFF" />
                </div>
                <div className='address-text'>
                  {location.address}
                </div>
                <hr className='app__locations-desktop-divider'/>
            </motion.div>
            <motion.div
              whileInView={{ x:[0,0], y: [50, 0], opacity: [0, 1] }}
              transition={{ duration: 0.7 }}
              className='app__locations-desktop-lesson-types'>
                {location.lessonTypes.map((lessonType, index) => (
                  <Card
                    key={index}
                    index={index}
                    title={lessonType.title}
                    subtitle={lessonType.subtitle}
                    contentTitle={lessonType.contentTitle}
                    content={lessonType.content}
                    finalTitle={lessonType.finalTitle}
                    date={lessonType.date}
                  />
                ))}
            </motion.div>
          </div>
        ))}
      </>
    );
  }

  return (
    <div className="app__locations">
        {isMobile ? renderMobileLocation() : renderDesktopLocation()}
    </div>
  )
}

export default Locations