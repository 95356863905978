import React from 'react'
import { images } from '../../constants';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import './PhotoGallery.scss';

const PhotoGallery = () => {
  const photos = [
    {
      id: 1,
      img: images.photoGallery1,
    },
    {
      id: 2,
      img: images.photoGallery2,
    },
    {
      id: 3,
      img: images.photoGallery3,
    },
    {
      id: 6,
      img: images.photoGallery6,
    },
    {
      id: 8,
      img: images.photoGallery8,
    },
    {
      id: 9,
      img: images.photoGallery9,
    },
    {
      id: 10,
      img: images.photoGallery10,
    }
  ];

  return (
    <div className="app__photoGallery">
      <Swiper
        cssMode={true}
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        speed={1000}
        className="mySwiper"
      >
        {photos.map((photo, index) => (
          <SwiperSlide>
          <div key={index} className="flicking-panel">
            <img src={photo.img} className='app__photoGallery-img' alt="A beautiful sunset over the ocean"/>
          </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default PhotoGallery